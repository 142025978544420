import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article01122020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T MOLD CONFIRMS THE HIGHEST STATUS IN HEWLETT PACKARD ENTERPRISE
              PARTNER PROGRAM
            </h1>
            <p>
              S&T Mold extended the status of Platinum Partner 2021 - the
              highest level of partnership, which implies a wide specialization
              in the fields of products and solutions of the world IT leader
              Hewlett Packard Enterprise.
            </p>
            <p>
              Hewlett Packard Enterprise Company provide the cutting-edge
              technology solutions enterprises need to optimize their
              traditional IT while helping them build a secure, cloud-enabled,
              mobile-ready future.
            </p>
            <p>
              The certification obtained indicates that S&T Mold has deep
              knowledge and experience in applying HPE technologies: from
              strategic planning to system integration and support for systems
              of any complexity. Today S&T Mold is a leading provider of IT
              solutions and services on the Moldovan IT market, as part of the
              S&T international group of companies that also operates in the
              Central and Eastern Europe.
            </p>
            <p>
              The many years' experience and the balanced product portfolio
              allows us to offer optimal solutions to financial and
              communications companies, public agencies, industrial companies
              and retail networks.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article01122020;
